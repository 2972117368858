import React from 'react';
import { Link, PageProps } from 'gatsby';
import BareLayout from '@/components/BareLayout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const PrivacyPolicy: React.FC<PageProps> = () => (
   <BareLayout pageTitle="Privacy Policy">
      <div className="container legal-info">
         <h1>Privacy Policy</h1>
         <h2>Introduction</h2>
         <p>Lingco Language Labs, Inc., a Delaware corporation, whose principal place of business is 1146 South Washington Square, Lansing, Michigan 48910 (<strong>“Lingco”</strong> or <strong>“We”</strong>) respects your privacy and is committed to protecting it through our compliance with this policy.</p>
         <p>This policy describes the types of information that we may collect from you or that you may provide when you visit <a href="https://lingco.io/">https://lingco.io/</a> or use our software platform (our <strong>“Platform”</strong>) and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
         <p>This policy applies to information we collect:</p>

         <ul>
            <li>On our Platform.</li>
            <li>In email, text, and other electronic messages between you and our Platform.</li>
         </ul>

         <p>It does not apply to information collected by any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Platform.</p>

         <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Platform. By accessing or using our Platform, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of our Platform after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>

         <h2>Information from Children</h2>
         <p>We rely on our educational institution customers to obtain the necessary parental consents prior to collecting information from students under age 13. Any information that we collect is used only for providing the services to the educational institutions. </p>

         <h2>Information We Collect About You and How We Collect It</h2>
         <p>We collect several types of information from and about users of our Platform, including information:</p>
         <ul>
            <li>By which you may be personally identified, such as name, student identification numbers, e-mail address, telephone number, or any other identifier by which you may be contacted online or offline (<strong>“personal information”</strong>);</li>
            <li>That is about you but individually does not identify you; and</li>
            <li>About your internet connection, the equipment you use to access our Platform, and usage details.</li>
         </ul>

         <p>We collect this information:</p>
         <ul>
            <li>Directly from you when you provide it to us.</li>
            <li>Automatically as you navigate through the Platform. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
            <li>From third parties, for example, if your educational institution contacts us about your account.</li>
         </ul>

         <h2>Information You Provide to Us</h2>
         <p>The information we collect on or through our Platform may include:</p>
         <ul>
            <li>Information that you submit on our Platform, including searches. This includes information provided at the time of registering to use our Platform, subscribing to our service, or requesting further services.</li>
            <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
            <li>Your responses to surveys that we might ask you to complete for research and customer satisfaction purposes.</li>
            <li>Details of transactions you carry out through our Platform and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Platform.</li>
         </ul>

         <p>You also may provide information to be published or displayed (<strong>“posted”</strong>) on public areas of the Platform, or transmitted to other users of the Platform or third parties (collectively, <strong>“User Contributions”</strong>). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages and you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Platform with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>

         <h2>Information We Collect Through Automatic Data Collection Technologies</h2>
         <p>As you navigate through and interact with our Platform, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
         <ul>
            <li>Details of your visits to our Platform, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Platform.</li>
            <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
         </ul>
         <p>We also may use these technologies to collect information about your online activities over time and across third-party Platforms or other online services (behavioral tracking). </p>
         <p>The information we collect automatically may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Platform and to deliver a better and more personalized service, including by enabling us to:</p>
         <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>Store information about your preferences, allowing us to customize our Platform according to your individual interests.</li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Platform.</li>
         </ul>

         <p>The technologies we use for this automatic data collection may include:</p>
         <ul>
            <li><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Platform. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Platform.</li>
            <li><strong>Web Beacons.</strong> Pages of our Platform and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Lingco, for example, to count users who have visited those pages or opened an email and for other related Platform statistics (for example, recording the popularity of certain Platform content and verifying system and server integrity).</li>
         </ul>

         <h2>How We Use Your Information</h2>
         <p>We do not sell your information. We do use information that we collect about you or that you provide to us, including any personal information:</p>
         <ul>
            <li>To present our Platform and its contents to you.</li>
            <li>To provide you with information, products, or services that you request from us.</li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>To provide you with notices about your account, including expiration and renewal notices.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
            <li>To notify you about changes to our Platform or any products or services we offer or provide though it.</li>
            <li>To allow you to participate in interactive features on our Platform.</li>
            <li>In any other way we may describe when you provide the information.</li>
            <li>For any other purpose with your consent.</li>
         </ul>
         <p>We may also use your information to contact you about goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see Choices About How We Use and Disclose Your Information.</p>

         <h2>Disclosure of Your Information</h2>
         <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
         <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
         <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business, although we expect these service providers to have a privacy policy that is at least as protective of your information as this privacy policy.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Lingco’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Lingco about our Platform users is among the assets transferred.</li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
            <li>With your consent.</li>
         </ul>
         <p>We may also disclose your personal information:</p>
         <ul>
            <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
            <li>To enforce or apply our terms of service and other agreements, including for billing and collection purposes.</li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Lingco, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
         </ul>

         <h2>Choices About How We Use and Disclose Your Information</h2>
         <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: </p>
         <ul>
            <li><strong>Tracking Technologies.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
         </ul>
         <p>California residents may have additional personal information rights and choices. Please see your California Privacy Rights for more information.</p>
         <p>Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to this designated address: <a href="mailto:policies@lingco.io">policies@lingco.io</a>. However, please know we do not currently sell data triggering that statute’s opt-out requirements.</p>

         <h2>Your Choices, Accessing and Correcting Your Information</h2>
         <p>You can review and change your personal information by logging into the Platform and visiting your account profile page.</p>
         <p>You may also send us an email at <a href="mailto:policies@lingco.io">policies@lingco.io</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete all of your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. Please note that we generally retain information only for as long as we deem necessary to fulfill the purpose for which we collected it. We delete information no longer necessary to fulfill its purpose within 30 days after we determine that it is no longer necessary. For example, if you terminate your account with us, we will delete your information within 30 days.</p>
         <p>California residents may have additional personal information rights and choices. Please see your California Privacy Rights for more information.</p>

         <h2>Data Security</h2>
         <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the safety and security of your information depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you are responsible for keeping this password confidential. Do not share your password with anyone.</p>
         <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Platform.</p>

         <h2>Changes to Our Privacy Policy</h2>
         <p>It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Platform home page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the primary email address specified in your account. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Platform and this privacy policy to check for any changes.</p>

         <h2>Your California Privacy Rights</h2>
         <p>California residents have certain rights with respect to their personal information, as described below. Before we may fulfill your requests, we are required by law to verify your identity in order to prevent unauthorized access to your data.</p>
         <p><strong>We do not and will not sell your personal information in connection with our education platforms.</strong></p>

         <h3>Right to Know and Access Information:</h3>
         <p>You may access information we maintain about you using the methods provided below. If we grant your request, we will provide you with a copy of the personal information we maintain about you in the ordinary course of business. This may include what personal information we collect, use, or disclose about you. We may not fulfill some or all of your request to access as permitted by applicable law.</p>

         <h3>Deletion of Information:</h3>
         <p>You may request that we delete your personal information. Depending on the scope of your request, we may refrain from granting your request, as permitted by applicable law. For example, we may be legally required to retain your information in our business records. You may submit a deletion request using the methods provided below.</p>

         <h3>Authorized Agent:</h3>
         <p>California residents may use an authorized agent on their behalf to exercise a privacy right discussed above. If you are an authorized agent acting on behalf of a California resident to communicate with us or to exercise a privacy right discussed above, you must be able to demonstrate that you have the requisite authorization to act on behalf of the resident and have sufficient access to their laptop, desktop, or mobile device to exercise these rights digitally. If you are an authorized agent trying to exercise rights on behalf of a user, please contact us at the contact information below with supporting verification information, which includes a valid Power of Attorney in the State of California, proof that you have access to the consumer’s interface, and proof of your own identity.</p>

         <h3>To Exercise Your Rights</h3>
         <p>In most cases, your educational institution provides your access to Lingco’s Platform. As such, to exercise your rights in relation to your use of the Platform, please contact your education institution. We will work with them to facilitate your request. If your request relates specifically to our website, please contact us at:</p>
         <ul>
            <li>Email <a href="mailto:policies@lingco.io">policies@lingco.io</a></li>
         </ul>
         <p> Please note that your exercise of the above rights is subject to certain exemptions to safeguard the public interest (e.g., the prevention or detection of crime) and our interests (e.g., the maintenance of legal privilege). We will try to comply with your request as soon as reasonably practicable. Requests to exercise these rights may be granted in whole, in part, or not at all, depending on the scope and nature of the request and applicable law. Where required by applicable law, we will notify you if we reject your request and notify you of any reasons we are unable to honor your request.</p>

         <p><strong>Non-discrimination:</strong> We will not discriminate or otherwise penalize anyone for exercising their rights under this Privacy Policy.</p>

         <h2>Your European Union Privacy Rights</h2>
         <p>European Union residents have certain additional rights with respect to their personal information, as described below.</p>
         <p>The right to access – You have the right to request copies of your personal data.</p>
         <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we correct or complete the information you believe is incomplete.</p>
         <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
         <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
         <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
         <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
         <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us:</p>
         <ul>
            <li>By email: <a href="mailto:policies@lingco.io">policies@lingco.io</a></li>
         </ul>

         <h2>Contact Information</h2>
         <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
         <p className="indent">1146 South Washington Square<br />
            Lansing, MI 48910</p>

         <p className="indent"><a href="mailto:policies@lingco.io">policies@lingco.io</a></p>

         <h2>Revision</h2>
         <p>Posted/Revised: 2021 January</p>
      </div>
   </BareLayout >
);

export default PrivacyPolicy;
